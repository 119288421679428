import { checkDateBeforeDay, getStosunekPTK, ifNullDate } from "./myfunctions";

export function getTopXMembers(jsonData, jsonDataMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 1) {
                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , member1_id: element2.team1_member_id
                                , member2_id: element2.team2_member_id
                            })
                        }

                    });
                }
            });
        });

        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_ratio: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0

                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {
                    if (element.member_id === element1.member1_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.member_id === element1.member2_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small
                element.total_points_ratio = getStosunekPTK(total_points_winners, total_points_losers)
            })
            return tmpMT.sort((a, b) => b.total_points_ratio - a.total_points_ratio
                || b.total_points_winners - a.total_points_winners
                || a.total_points_losers - b.total_points_losers
                || (b.total_points_winners_small - b.total_points_losers_small) - (a.total_points_winners_small - a.total_points_losers_small))
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}



export function getTopXPtkMembers(jsonData, jsonDataMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null) {
                            tmpM.push({ ...element2, details_score: null })
                        }

                    });
                }
            });
        });



        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points: 0, total_points_matches_single: 0, total_points_winners_single: 0, total_points_losers_single: 0,
                        total_points_matches_debel: 0, total_points_winners_debel: 0, total_points_losers_debel: 0

                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points = 0
                let total_points_matches_single = 0
                let total_points_winners_single = 0
                let total_points_losers_single = 0
                let total_points_matches_debel = 0
                let total_points_winners_debel = 0
                let total_points_losers_debel = 0

                tmpM.forEach(element1 => {

                    if (element.member_id === element1.team1_member_id && element1.type === 1) {
                        total_points_matches_single++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points += 1
                            total_points_winners_single++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers_single++
                        }

                    } else if ((element.member_id === element1.team1_member1_id || element.member_id === element1.team1_member2_id) && element1.type === 2) {
                        total_points_matches_debel++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points += 0.5
                            total_points_winners_debel++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers_debel++
                        }

                    } else if (element.member_id === element1.team2_member_id && element1.type === 1) {
                        total_points_matches_single++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers_single++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points += 1
                            total_points_winners_single++
                        }
                    } else if ((element.member_id === element1.team2_member1_id || element.member_id === element1.team2_member2_id) && element1.type === 2) {
                        total_points_matches_debel++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers_debel++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points += 0.5
                            total_points_winners_debel++
                        }
                    }

                })
                element.total_points = total_points
                element.total_points_matches_debel = total_points_matches_debel
                element.total_points_losers_debel = total_points_losers_debel
                element.total_points_winners_debel = total_points_winners_debel
                element.total_points_matches_single = total_points_matches_single
                element.total_points_winners_single = total_points_winners_single
                element.total_points_losers_single = total_points_losers_single
            })

            return tmpMT.sort((a, b) => b.total_points - a.total_points)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}

export function getTopXDebelMembers(jsonData, jsonDataMembers, countMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 2) {

                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , t1_member1_id: element2.team1_member1_id
                                , t1_member2_id: element2.team1_member2_id
                                , t2_member1_id: element2.team2_member1_id
                                , t2_member2_id: element2.team2_member2_id
                                , t1_hash: element2.team1_member1_id + "_" + element2.team1_member2_id
                                , t2_hash: element2.team2_member1_id + "_" + element2.team2_member2_id
                            })
                        }

                    });
                }
            });
        });


        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_name: element.name, team_img: element.img_file, team_id: element.member_id,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_ratio: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });
        // petla po zawodnikach deblowych z kasowaniem dubli same unikalne wartości
        let memberOfDebel = []
        tmpM.forEach(element => {
            if (!memberOfDebel.filter(function (item) { return item.thash === element.t1_hash; }).length > 0) {
                let member1 = tmpMT.filter(function (item) { return item.member_id === element.t1_member1_id; })
                let member2 = tmpMT.filter(function (item) { return item.member_id === element.t1_member2_id; })

                if (member1 && member1.length === 1 && member2 && member2.length === 1) {
                    memberOfDebel.push({
                        thash: element.t1_hash, member1_id: element.t1_member1_id, member2_id: element.t1_member2_id
                        , member1_name: member1[0].member_name, member1_img: member1[0].member_img
                        , member2_name: member2[0].member_name, member2_img: member2[0].member_img
                        , team_name: member1[0].team_name, team_img: member1[0].team_img, team_id: member1[0].team_id
                    })
                }

            }

            if (!memberOfDebel.filter(function (item) { return item.thash === element.t2_hash; }).length > 0) {
                let member1 = tmpMT.filter(function (item) { return item.member_id === element.t2_member1_id; })
                let member2 = tmpMT.filter(function (item) { return item.member_id === element.t2_member2_id; })
                if (member1 && member1.length === 1 && member2 && member2.length === 1) {
                    memberOfDebel.push({
                        thash: element.t2_hash, member1_id: element.t2_member1_id, member2_id: element.t2_member2_id
                        , member1_name: member1[0].member_name, member1_img: member1[0].member_img
                        , member2_name: member2[0].member_name, member2_img: member2[0].member_img
                        , team_name: member1[0].team_name, team_img: member1[0].team_img, team_id: member1[0].team_id

                    })
                }
            }


        });



        //console.log("asdasd",tmpM)

        if (memberOfDebel.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            memberOfDebel.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0
                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {

                    if (element.thash === element1.t1_hash) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.thash === element1.t2_hash) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small
                element.total_points_ratio = getStosunekPTK(total_points_winners, total_points_losers)
            })

            return memberOfDebel.sort((a, b) => b.total_points_ratio - a.total_points_ratio
                || b.total_points_winners - a.total_points_winners
                || a.total_points_losers - b.total_points_losers
                || (b.total_points_winners_small - b.total_points_losers_small) - (a.total_points_winners_small - a.total_points_losers_small)).slice(0, countMembers)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}


export function getMembersStatsAll(jsonData, jsonDataMembers) {
    try {


        function getValueData(value, jsontype, prevValue) {

            if (value === null) {
                return 0 + prevValue
            } else if (jsontype === 'in_match' || jsontype === 'mvp') {
                if (value) {
                    // true
                    return 1 + prevValue
                } else {
                    return 0 + prevValue
                }
            } else if (jsontype === 'matche_cards') {

                if (prevValue === 0) {
                    prevValue = { yellow: 0, yr: 0, red: 0 }
                }

                if (value === 'red') {
                    return { ...prevValue, red: prevValue.red + 1 }
                } else if (value === 'yellow-red') {
                    return { ...prevValue, yr: prevValue.yr + 1 }
                } else if (value === 'yellow') {
                    return { ...prevValue, yellow: prevValue.yellow + 1 }
                }
            } else {
                return Number(value) + prevValue
            }

        }

        let tmpM = []
        // tworzymy tablicę statsow
        if (jsonData
            && jsonData.length > 0
            && jsonData[0].matches
            && jsonData[0].matches.length > 0
            && jsonData[0].matches[0].match_details_ex
            && jsonData[0].matches[0].match_details_ex.members
        ) {

            for (const key in jsonData[0].matches[0].match_details_ex.members) {
                tmpM.push({ key: key, sort: jsonData[0].matches[0].match_details_ex.members[key].sort, value: 0 })
            }

        }




        let tmpMT = []
        // lista membersów w drużynie

        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        member_team_id: element1.member_team_id,
                        stats: tmpM.sort((a, b) => a.sort - b.sort),
                    })
                });
            }

        });




        if (tmpMT.length > 0 && jsonData && tmpM.length > 0) {
            // loop po zawodnikach
            let tmpMTtmp = JSON.parse(JSON.stringify(tmpMT));
            tmpMT.forEach((element, j) => {
                // loop po stats
                element.stats.forEach((elementStats, i) => {
                    //count_assist oraz konkretny members

                    jsonData.forEach(jsonDataElement => {
                        jsonDataElement.matches.forEach(jsonDataElementMatches => {
                            if (jsonDataElementMatches.match_details_ex && jsonDataElementMatches.match_details_ex.members) {

                                if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_id === element.team_id) {
                                    try {
                                        if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value) {
                                            tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value, elementStats.key, tmpMTtmp[j].stats[i].value)
                                        }
                                    } catch (e) {
                                        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                            console.log(e)
                                        }
                                    }

                                } else if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_id === element.team_id) {
                                    try {
                                        if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value) {
                                            tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value, elementStats.key, tmpMTtmp[j].stats[i].value)
                                        }
                                    } catch (e) {
                                        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                            console.log(e)
                                        }
                                    }

                                }

                            }
                        })
                    })


                })


            })

            // sortowanie po ostatniej statystyce
            return tmpMTtmp.sort((a, b) => b.stats[a.stats.length - 1].value - a.stats[a.stats.length - 1].value)
        }
        else {
            return []
        }


    } catch (e) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(e)
        }
        return []
    }
}


export function getMembersStatsLeague(member_id, jsonData) {
    try {

        if (jsonData.matches) {



            let total_points_winners = 0
            let total_points_losers = 0
            let total_points_winners_small = 0
            let total_points_losers_small = 0
            // tworzymy tablicę wszystkich pojedynków
            jsonData.matches.forEach(element => {
                if (element.matche_ts) {
                    element.matche_ts.forEach(element1 => {
                        if (member_id === element1.team1_member_id && element1.type === 1) {

                            if (element1.score_member1 > element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_losers++
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                            }

                        } else if (member_id === element1.team2_member_id && element1.type === 1) {

                            if (element1.score_member1 > element1.score_member2) {
                                total_points_losers++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            }
                        }
                    });
                }

            });

            return { matches: { win: total_points_winners, lose: total_points_losers }, points: { win: total_points_winners_small, lose: total_points_losers_small } }
        } else {
            return null
        }

    } catch (e) {
        console.log(e)
        return null
    }
}


export function getupComingMatches(json, countDay) {
    try {
        let tmp = []
        if (json && json.length > 0) {
            // runda
            json.forEach((row) => {
                // mecz
                if (row.matches && row.matches.length > 0) {
                    row.matches.forEach((rowm) => {
                        // sprwadzamy czy jest czas ustawiony

                        if (rowm.match_date && checkDateBeforeDay(rowm.match_date, countDay) && rowm.member1_score === null) {

                            tmp.push({ ...rowm, league_revenge_round: row.league_revenge_round, round_nr: row.round_nr })
                        }

                    })
                }

            })
        }
        if (tmp.length > 0) {
            return tmp.sort((a, b) => ifNullDate(a.match_date) - ifNullDate(b.match_date))
        }
        return tmp
    } catch (e) {
        console.log(e)
        return []
    }
}