import React, { useState, useEffect, useContext } from 'react';
import { Modal, Container, Button, Form, Message, Select, Grid, Table, Segment, Icon, Input, Label } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../ConnectWS'
import AuthContext from '../../../context/authContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import bergerTable from 'berger-table-generator';
import useAuth from '../../../hooks/useAuth';
import { blockEditScore, getInfoTab, getInfroMatchPlace, getStosunekPTK, getlinkplayerprofile, mobileWSmall } from '../../../myfunctions';
import BracketGenHarm from './BracketGenHarm';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import GetScoreDetails from './GetScoreDetails';
import ShowScoreDetails from './ShowScoreDetails';
import CurrentDuelsPlayingGroups from './CurrentDuelsPlayingGroups';
import { useTranslation } from 'react-i18next';





function BracketGroupsEWE(propsRoot) {
    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);
    const [openModalScore, setopenModalScore] = useState(null)// eslint-disable-line no-unused-vars
    const [openUsersAdd, setOpenUsersAdd] = useState(false)
    const [tournamentGroups, settournamentGroups] = useState()
    const [formUsersAdd, setfromUsersAdd] = useState({ subtournament_group_member_id: 0, member_id: -100 })
    const [messageStatus, setmessageStatus] = useState({ state: 0, text: "" })
    const [tournamentId, settournamentId] = useState(0)// eslint-disable-line no-unused-vars
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [userList, setuserList] = useState([])
    const [isCupGened, setisCupGened] = useState(false)// eslint-disable-line no-unused-vars
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [openModalHarm, setopenModalHarm] = useState(false)
    const [loading, setloading] = useState(false)
    const [checkboxMD, setcheckboxMD] = useState(true) // eslint-disable-line no-unused-vars
    const { t } = useTranslation();


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps


        if (propsRoot.remoteGroups && propsRoot.struct_confirmed === 0) {
            settournamentGroups(propsRoot.remoteGroups)
            settournamentId(propsRoot.tournamentId)
            getListUsers(propsRoot.tournamentId)
        }
        else if (propsRoot.remoteGroups && propsRoot.struct_confirmed === 1) {

            settournamentGroups(propsRoot.remoteGroups)
            settournamentId(propsRoot.tournamentId)
        } else {
            console.log("BRAK JSONA")
        }

        if (propsRoot.subtournaments && propsRoot.subtournaments.length > 0) {
            let tmp1 = 0
            propsRoot.subtournaments.forEach((line, i) => {
                if (line.parent_subtournament_id === propsRoot.tournamentId) {
                    tmp1++
                }
            })
            if (tmp1 > 0) {
                setisCupGened(true)
            } else {
                setisCupGened(false)
            }
        } else {
            setisCupGened(false)
        }


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.struct_confirmed, propsRoot.remoteGroups, propsRoot.subtournaments, propsRoot.tournamentId, propsRoot.linkTournament])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    function getListUsers(subtournament_id) {
        ConnectWS("/generator/getUnassignedSubtournamentMembers", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let dictemat = []
                dictemat.length = 0
                response.data.forEach((data, i) => {
                    dictemat.push({ key: i, text: data.member_name, value: data.member_id })
                    //dictemat.push({ key: i, text: data.Login, value: data.Login })
                })

                setuserList(dictemat);
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }







    function exitModal() {
        setmessageStatus({ state: 0, text: "" })
        setfromUsersAdd({ subtournament_group_member_id: 0, member_id: -100 })
        setopenModalScore(null)
        setOpenUsersAdd(false)
    }


    function OpenModalUser(subtournament_group_member_id, member_id = -100) {
        setfromUsersAdd({ subtournament_group_member_id: subtournament_group_member_id, member_id: member_id })
        setOpenUsersAdd(true)

    }

    function removeMemberFromGroup(subtournament_group_member_id) {

        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować uczestnika ',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeMemberFromGroupA(subtournament_group_member_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function removeMemberFromGroupA(subtournament_group_member_id) {

            ConnectWS("/generator/removeMemberFromGroup ", authContext.token, { subtournament_group_member_id: subtournament_group_member_id }, function (response, logout) {

                if (logout === 1) {

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    addToast(response, { appearance: 'success' });
                }



            });
        }
    }



    function modifiDataJsonUserACC() {

        if (formUsersAdd.member_id < -90) {
            setmessageStatus({ state: 1, text: "Wybierz uczestnika" })
            return
        }

        let member_id = formUsersAdd.member_id === -2 ? null : formUsersAdd.member_id === -1 ? 0 : formUsersAdd.member_id
        let subtournament_group_member_id = formUsersAdd.subtournament_group_member_id
        setloading(true)
        ConnectWS("/generator/addMemberToGroup", authContext.token, { subtournament_group_member_id: subtournament_group_member_id, member_id: member_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                //let textt = openData.sides.home.team.name + " [" + form.homeScore + ":" + form.visitorScore + "] " + openData.sides.visitor.team.name
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)



        });
    }





    function checkScoreGroupsMM(line, m1, m2, arrMatch, a, b) {
        return line.matches.map(line4 => {
            if (line4.subtournament_group_member1_id === m1 && line4.subtournament_group_member2_id === m2) {

                if (line4.member1_score != null && line4.member2_score != null && line4.member1_score >= 0 && line4.member2_score >= 0) {
                    return <><span style={{ fontWeight: "bold", color: line4.member1_score > line4.member2_score ? "#03ab21" : line4.member1_score === line4.member2_score ? "#d9a60b" : "#e82c2c" }}>{line4.member1_score}:{line4.member2_score}</span> {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' title={"Edytuj wynik: [" + line4.member1_name + "]-[" + line4.member2_name + "]"} size='small' name="edit" color='grey' onClick={() => setopenModalScore({ subtournament_group_member1_id: line4.subtournament_group_member1_id, subtournament_group_member2_id: line4.subtournament_group_member2_id, subtournament_group_match_id: line4.subtournament_group_match_id, member1_score: line4.member1_score, member2_score: line4.member2_score, member1_name: line4.member1_name, member2_name: line4.member2_name, match_details: line4.match_details, match_place: line4.match_place })} />}<ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: false, checkboxMD: checkboxMD }} /></>;
                } else {
                    return <>{getInfroMatchPlace(line4.match_place)}{propsRoot.onlyView === 1 ? "" : <><Icon className='cursorPointer hiddenPrint' title={"Dodaj wynik: [" + line4.member1_name + "]-[" + line4.member2_name + "]"} name="add" onClick={() => setopenModalScore({ subtournament_group_member1_id: line4.subtournament_group_member1_id, subtournament_group_member2_id: line4.subtournament_group_member2_id, subtournament_group_match_id: line4.subtournament_group_match_id, member1_score: line4.member1_score, member2_score: line4.member2_score, member1_name: line4.member1_name, member2_name: line4.member2_name, match_details: line4.match_details, match_place: line4.match_place })} /> </>}<ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: false, checkboxMD: checkboxMD }} /></>;
                }

            } else if (line4.subtournament_group_member1_id === m2 && line4.subtournament_group_member2_id === m1) {
                if (line4.member1_score != null && line4.member2_score != null && line4.member1_score >= 0 && line4.member2_score >= 0) {
                    arrMatch.push(reverseScore((b + 1), (a + 1)))
                    return <><span style={{ fontWeight: "bold", color: line4.member1_score < line4.member2_score ? "#03ab21" : line4.member1_score === line4.member2_score ? "#d9a60b" : "#e82c2c" }}>{line4.member2_score}:{line4.member1_score} </span>  <ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: true, checkboxMD: checkboxMD }} /></>
                } else {
                    return <>{getInfroMatchPlace(line4.match_place)} <ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: true, checkboxMD: checkboxMD }} /></>;
                }
            }
            return ""
        })

    }

    function showScoreDetailsSmall(line) {
        try {

            if (line.matches && line.matches.length > 0) {
                for (const row of line.matches) {
                    if (row.match_details != null && JSON.parse(row.match_details).score) {
                        return true
                    }
                }
            }
            return false
        } catch (e) {
            return false
        }

    }

    function getScoreDetailsSmall(line, line4) {
        try {
            let W = 0;
            let L = 0;
            if (line.matches && line.matches.length > 0) {
                for (const row of line.matches) {
                    if (row.match_details != null && JSON.parse(row.match_details).score && (line4.subtournament_group_member_id === row.subtournament_group_member1_id || line4.subtournament_group_member_id === row.subtournament_group_member2_id)) {
                        for (const rowS of JSON.parse(row.match_details).score) {
                            W = W + rowS[line4.subtournament_group_member_id]
                            if (row.subtournament_group_member1_id === line4.subtournament_group_member_id) {
                                L = L + rowS[row.subtournament_group_member2_id]
                            } else {
                                L = L + rowS[row.subtournament_group_member1_id]
                            }
                        }

                    }
                }
            }
            return <>{W + ":" + L}<br /><span style={{ fontSize: 11 }}>({getStosunekPTK(W, L)})</span></>
        } catch (e) {
            return ""
        }

    }


    function generatorTable(line) {
        let arrMatch = []
        let showScoreD = showScoreDetailsSmall(line)
        return <><Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ width: 40 }} textAlign='center'>LP</Table.HeaderCell>
                    <Table.HeaderCell style={{ fontSize: 12 }} textAlign='center' width={5}>{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                    {line && line.members ? line.members.map((line4, i) =>
                        <>

                            <Table.HeaderCell textAlign='center'>
                                {i + 1}
                            </Table.HeaderCell>
                        </>
                    )
                        : ""}
                    <Table.HeaderCell style={{ width: 80 }} textAlign='center'>{t("WidokTurnieju.tabela_ptk1")}</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: 80,lineHeight: "12px" }} textAlign='center'>{t("WidokTurnieju.tabela_bilans")}<br /><span style={{ fontSize: 11 }}>({t("WidokTurnieju.tabela_stosunek")})</span></Table.HeaderCell>
                    {showScoreD ? <Table.HeaderCell style={{ width: 80,lineHeight: "12px" }} textAlign='center'>{t("WidokTurnieju.tabela_bilans")}<br /><span style={{ fontSize: 9 }}>{t("WidokTurnieju.tabela_bilans_szczegoly")}</span><br /><span style={{ fontSize: 11 }}>({t("WidokTurnieju.tabela_stosunek")})</span></Table.HeaderCell> : ""}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {line && line.members ?

                    line.members.map((line4, i) =>

                        <Table.Row>
                            <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                            <Table.Cell textAlign='left' style={{ fontSize: 12 }}>
                                <figure className='imgPlayer'><ShowImgAvatar urlpic={line4.member_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, line4.member_name, 0, width<mobileWSmall() ? 13 : 14)}</figcaption></figure>
                            </Table.Cell>
                            {line && line.members ? line.members.map((line5, j) =>
                                <>

                                    <Table.Cell style={{ position: "relative" }} disabled={i === j ? true : false} textAlign='center' singleLine>

                                        {i === j ? <Icon name="x" size='large' /> : checkScoreGroupsMM(line, line4.subtournament_group_member_id, line5.subtournament_group_member_id, arrMatch, i, j)}
                                    </Table.Cell>
                                </>
                            )
                                : ""}
                            {line && line.result_table ? line.result_table.map((line6, j) =>
                                line6.subtournament_group_member_id === line4.subtournament_group_member_id ?
                                    <>
                                        <Table.Cell textAlign='center'>
                                            {line6.subtournament_point}
                                        </Table.Cell>
                                        <Table.Cell textAlign='center' singleLine>
                                            {line6.total_points_winners}:{line6.total_points_losers} <span style={{ fontSize: 11 }}><br />({getStosunekPTK(line6.total_points_winners, line6.total_points_losers)})</span>
                                        </Table.Cell>

                                    </>
                                    : ""
                            )
                                : ""}
                            {showScoreD ? <Table.Cell textAlign='center'>{getScoreDetailsSmall(line, line4)}</Table.Cell> : ""}
                        </Table.Row>

                    )

                    : ""}



            </Table.Body>


        </Table>

            <div style={{ overflowX: "auto", width: width < 700 ? (width - 130) : "100%", fontSize: 13, marginTo: 5, color: "#b1b1b1", lineHeight: "16px" }} >
                {t("WidokTurnieju.tabela_bergera")}:<br /> {generatorMatchCount(line.members.length, arrMatch)}
            </div>
        </>
    }

    function saveScore() {
        let nmin = 0
        let nmax = 200
        let removeScore = false;

        if (openModalScore.member1_score === null && openModalScore.member2_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik meczu " })
            return
        }
        if (openModalScore.member1_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla czerwonego" })
            return
        } else if (openModalScore.member1_score < nmin || openModalScore.member1_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (openModalScore.member2_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla niebieskiego" })
            return
        } else if (openModalScore.member2_score < nmin || openModalScore.member2_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }

        if (openModalScore.member1_score === "" || openModalScore.member2_score === "") {
            removeScore = true
        }

        let jsonObj = { ...openModalScore, member1_score: openModalScore.member1_score, member2_score: openModalScore.member2_score, removeScore: removeScore }
        setloading(true)
        ConnectWS("/generator/setMatchScoreMembersGroup", authContext.token, jsonObj, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)


        });

    }

    function delScore() {
        setloading(true)
        ConnectWS("/generator/setMatchScoreMembersGroup", authContext.token, { ...openModalScore, removeScore: true }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)


        });
    }

    const saveScorePre = async e => {
        e.preventDefault();
        saveScore()
    }

    function reverseScore(a, b) {
        if (Number(a) > Number(b)) {
            return (b + "-" + a)
        } else {
            return (a + "-" + b)
        }

    }

    function generatorMatchCount(countMatches, arrMatchTmp) {
        try {
            let arr = bergerTable(countMatches)
            if (arr && arr.length > 0) {
                return arr.map((row, i) => {
                    return row.map((row1, i) => {
                        if (arrMatchTmp.includes(reverseScore((row1.teamA + 1), (row1.teamB + 1)))) {
                            return <span style={{ marginRight: 5, fontSize: 12 }}><s><i>{reverseScore((row1.teamA + 1), (row1.teamB + 1))}</i></s>,</span>
                        } else {
                            return <span style={{ marginRight: 5, fontSize: 14, color: "#000" }}>{reverseScore((row1.teamA + 1), (row1.teamB + 1))},</span>
                        }

                    })
                })
            } else {
                return ""
            }
        } catch (err) {
            console.log(err)
        }

    }




    return (
        <>

            {tournamentGroups && tournamentGroups.length > 0 && propsRoot.struct_confirmed === 0 ?

                <Grid stackable style={{ marginBottom: 0 }}>
                    <Grid.Row stretched columns={tournamentGroups.length}>
                        {tournamentGroups.map((line, i) => (
                            <Grid.Column key={i}>
                                <Segment>

                                    <div style={{ overflowX: "auto", width: width < 700 ? (width - 200) : "100%" }} >
                                        <Table size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>

                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>

                                                {line && line.members ?
                                                    line.members.map((line3, i) => <Table.Row key={i}>
                                                        <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            {line3.member_id === null ? <Icon name="add" className='cursorPointer hiddenPrint' title="Przypisz uczestnika" onClick={() => OpenModalUser(line3.subtournament_group_member_id, line3.member_id)} />
                                                                : <> {line3.member_name} <Icon onClick={() => removeMemberFromGroup(line3.subtournament_group_member_id)} className='deluserico cursorPointer' name="trash" /></>}

                                                        </Table.Cell>

                                                    </Table.Row>)
                                                    : ""}

                                            </Table.Body>


                                        </Table>
                                    </div>

                                </Segment>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </Grid>

                : tournamentGroups && tournamentGroups.length > 0 && propsRoot.struct_confirmed === 1 ?
                    <>
                        {propsRoot.onlyView === 5 || propsRoot.viewTV === 1 || propsRoot.subtournament_finished===true ? '' : <>
                            <Button size='mini'  icon labelPosition='left' style={{ marginBottom: 10, marginTop: 20,width:"100%" }} className='hiddenPrint' color={propsRoot.onlyView === 1 ? '' : 'brown'} onClick={() => setopenModalHarm(true)}> <Icon name='file' />{propsRoot.onlyView === 1 ? t("WidokTurnieju.zobacz_harmonogram_gier") : "GENERUJ HARMONOGRAM GIER PDF/CSV"}</Button>
                            <BracketGenHarm onlyView={propsRoot.onlyView} linkTournament={propsRoot.linkTournament} countMatches={tournamentGroups} show={openModalHarm} setopenModalHarm={setopenModalHarm} />
                        </>}
                        {propsRoot.onlyView === 1 ? '' : <CurrentDuelsPlayingGroups tournamentJsonRoot={propsRoot.tournamentJsonRoot} subtournament_id={propsRoot.tournamentId} tournamentGroups={tournamentGroups} setopenModalScore={setopenModalScore} />}

                        <Grid stackable style={{ marginBottom: 0 }}>
                            <Grid.Row stretched columns={1}>
                                {tournamentGroups.map((line, i) => (

                                    <Grid.Column key={i} style={{ marginBottom: 10 }}>
                                        <Segment className='CardBlack' >
                                            {/*propsRoot.viewTV === 1 ? "" :
                                                <div className='smallchbox'>
                                                    <Checkbox className='hiddenPrint' toggle label={t("PokazWynikiWszczegolowe")} checked={checkboxMD} onChange={(e, d) => setcheckboxMD(d.checked)} />
                                                </div>
                                                */
                                            }
                                            <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                                {generatorTable(line)}
                                            </div>

                                        </Segment>


                                        {propsRoot.currentSubtournament === null || propsRoot.currentSubtournament.subtournament_finished ? "" :
                                            <Segment className='CardBlack'  >
                                                <Label color={'blue'} ribbon>
                                                    {t("WidokTurnieju.tabela")}
                                                </Label>
                                                {propsRoot.onlyView === 1 ? '' : getInfoTab()}
                                                <div style={{ overflowX: "auto", width: "100%", marginTop: 5 }} >

                                                    <Table className='stickyf2c' size='small' singleLine striped compact unstackable inverted={propsRoot.isDarkTheme}>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell singleLine width={1} textAlign='center' title="Miejsce">{width > 1000 ? t("WidokTurnieju.tabela_miejsce") : t("WidokTurnieju.tabela_miejsce_mala")}</Table.HeaderCell>
                                                                <Table.HeaderCell textAlign='center' >{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine title="Punktacja">{t("WidokTurnieju.tabela_ptk")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine title="Mecze">{t("WidokTurnieju.tabela_pojedynki")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine title="Wygrana">{t("WidokTurnieju.tabela_wygrane")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine title="Remis">{t("WidokTurnieju.tabela_remis")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine title="Przegrane">{t("WidokTurnieju.tabela_przegrane")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine title="Bilans">{t("WidokTurnieju.tabela_bilans")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine>{t("WidokTurnieju.tabela_stosunek")}</Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{t("WidokTurnieju.tabela_bilans")}
                                                                    <br /><span style={{ fontSize: 9 }}>{t("WidokTurnieju.tabela_bilans_szczegoly")}</span></Table.HeaderCell>
                                                                <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{t("WidokTurnieju.tabela_stosunek")}
                                                                    <br /><span style={{ fontSize: 9 }}>{t("WidokTurnieju.tabela_bilans_szczegoly")}</span></Table.HeaderCell>

                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>

                                                            {propsRoot.currentSubtournament && propsRoot.currentSubtournament.final_table && propsRoot.currentSubtournament.final_table.length > 0 ?
                                                                propsRoot.currentSubtournament.final_table.map((line3, i) =>
                                                                    <Table.Row key={i}  >

                                                                        <Table.Cell textAlign='center' singleLine>{line3.order_nr_range}</Table.Cell>
                                                                        <Table.Cell textAlign='center' ><figure className='imgPlayer'><ShowImgAvatar urlpic={line3.img_file} mtype={"0"} /><figcaption>{getlinkplayerprofile(line3.member_id, line3.member_name,1,width<mobileWSmall() ? 13 : 16,"_blank")}</figcaption></figure></Table.Cell>
                                                                        <Table.Cell textAlign='center'>{line3.subtournament_point} </Table.Cell>
                                                                        <Table.Cell textAlign='center'>{line3.matches_played}</Table.Cell>
                                                                        <Table.Cell textAlign='center'>{line3.wins}</Table.Cell>
                                                                        <Table.Cell textAlign='center'>{line3.draws}</Table.Cell>
                                                                        <Table.Cell textAlign='center'>{line3.loses}</Table.Cell>
                                                                        <Table.Cell textAlign='center' singleLine>{line3.total_points_winners} : {line3.total_points_losers}</Table.Cell>
                                                                        <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_points_winners, line3.total_points_losers)}</Table.Cell>
                                                                        <Table.Cell textAlign='center' singleLine>{line3.total_details_points_winners ? line3.total_details_points_winners : 0}:{line3.total_details_points_losers ? line3.total_details_points_losers : 0}</Table.Cell>
                                                                        <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_details_points_winners ? line3.total_details_points_winners : 0, line3.total_details_points_losers ? line3.total_details_points_losers : 0)}</Table.Cell>

                                                                    </Table.Row>)
                                                                : ""}

                                                        </Table.Body>

                                                    </Table>

                                                </div>
                                            </Segment>

                                        }
                                    </Grid.Column>
                                ))}
                            </Grid.Row>
                        </Grid>



                    </>

                    : ""
            }
            <Modal
                onClose={() => exitModal()}
                onOpen={() => setOpenUsersAdd(true)}
                open={openUsersAdd}
                centered={width < 900 ? false : true}
                closeIcon
                dimmer='blurring'

            >
                <Modal.Header>Przypisz uczestnika</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Container textAlign='left'>

                            <Form size='small' >
                                <Form.Field
                                    control={Select} selectOnBlur={false}
                                    options={userList}
                                    value={formUsersAdd.member_id}
                                    label="Wybierz uczestnika"
                                    onChange={(event, data) => {
                                        setfromUsersAdd({ ...formUsersAdd, member_id: data.value })
                                    }}
                                    search
                                />
                            </Form>

                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>

                    <Button size="tiny" color='black' onClick={() => exitModal()}>
                        WYJDŹ
                    </Button>
                    <Button size="tiny"
                        content="ZATWIERDŹ"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => modifiDataJsonUserACC()}
                        color='google plus'
                        loading={loading}
                        disabled={loading || formUsersAdd.member_id === null}
                    />
                </Modal.Actions>
            </Modal>

            <Modal

                open={openModalScore !== null ? true : false}
                centered={width < 900 ? false : true}



            >
                <Modal.Header>Wprowadź wynik</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
                    <Modal.Description>
                        <Container textAlign='center'>
                        <Segment >
                            <Form size='small' onSubmit={saveScorePre}>
                                <Form.Field inline>
                                    <Grid verticalAlign='middle' fluid stackable columns={3}>
                                        <Grid.Row>
                                            <Grid.Column width={7} textAlign='center'><b><label style={{ fontSize: 16, color: "#646bfa" }}>{openModalScore ? openModalScore.member1_name : ""}</label></b><br />
                                                <Input type="number" disabled={blockEditScore(openModalScore ? openModalScore.member1_score : 0, openModalScore ? openModalScore.member2_score : 0, propsRoot.systemId, openModalScore ? openModalScore.match_details : null)} autoFocus fluid value={openModalScore ? openModalScore.member1_score : 0} onChange={(e, d) => setopenModalScore({ ...openModalScore, member1_score: d.value, match_details: propsRoot.systemId === 5 ? null : openModalScore.match_details })} style={{ padding: 5, margin: 5, borderRadius: 5 }} />
                                            </Grid.Column>
                                            <Grid.Column textAlign='center' width={2}>
                                                <label style={{ fontSize: 25, fontWeight: "bold", color: "#737373" }}>VS</label>
                                            </Grid.Column>
                                            <Grid.Column width={7} textAlign='center'>
                                                <b><label style={{ fontSize: 16, color: "#fa6464" }} >{openModalScore ? openModalScore.member2_name : ""}</label></b><br />
                                                <Input disabled={blockEditScore(openModalScore ? openModalScore.member1_score : 0, openModalScore ? openModalScore.member2_score : 0, propsRoot.systemId, openModalScore ? openModalScore.match_details : null)} type="number" fluid value={openModalScore ? openModalScore.member2_score : 0} onChange={(e, d) => setopenModalScore({ ...openModalScore, member2_score: d.value, match_details: propsRoot.systemId === 5 ? null : openModalScore.match_details })} style={{ padding: 5, margin: 5, borderRadius: 5 }} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>

                               

                                <Button size="tiny"
                                    content="ZATWIERDŹ WYNIK"
                                    labelPosition='right'
                                    icon='checkmark'
                                    loading={loading}
                                    disabled={loading}
                                    color='google plus'
                                    fluid
                                    style={{ marginTop: 5 }}
                                />
                            </Form>
                            </Segment>
                            {openModalScore ?
                                    <GetScoreDetails tournamentJsonRoot={propsRoot.tournamentJsonRoot} tournament_free={propsRoot.tournament_free} systemId={propsRoot.systemId} openModalScoreTMP={{ subtournament_match_id: openModalScore.subtournament_group_match_id, member1_name: openModalScore.member1_name, member2_name: openModalScore.member2_name, member1_score: openModalScore.member1_score, member2_score: openModalScore.member2_score, match_details: openModalScore.match_details, match_place: openModalScore.match_place, match_member1_id: openModalScore.subtournament_group_member1_id, match_member2_id: openModalScore.subtournament_group_member2_id }} openModalScore={openModalScore} setopenModalScore={setopenModalScore} />
                                    : ""}
                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>
                    <Button floated='left' size="tiny" color='grey' labelPosition='right' icon='trash' onClick={() => delScore()} content="KASUJ WYNIK" />
                    <Button size="tiny" color='black' onClick={() => exitModal()}>
                        WYJDŹ
                    </Button>

                </Modal.Actions>
            </Modal>

        </>
    )
}

export default BracketGroupsEWE;