import React, { useRef, useState, useEffect } from 'react'
import styles from './CountDownTimer.module.css';
import { Icon, Message } from 'semantic-ui-react'
import { useTranslation } from "react-i18next";
import { differenceInSeconds } from "date-fns";
import useWindowDimensions from '../../../hooks/useWindowDimensions';



function CountDownTimer(props) {
  let interval = useRef(null);

  const { t } = useTranslation();
  const [rDays, setRDays] = useState(9999);
  const [rHour, setRHour] = useState(9999);
  const [rMin, setRMin] = useState(9999);
  const [rSec, setRSec] = useState(9999);
  const [functStatus, setfunctStatus] = useState(0);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

  function getTimeTL() {

    var start = new Date()
    var end = new Date(props.endDate)

    if (start > end) {
      clearInterval(interval.current)
      return 1
    }

    let timediff = differenceInSeconds(end, start)

    const remainingDays = Math.floor(timediff / (60 * 60 * 24))
    const remainingHour = Math.floor((timediff % (60 * 60 * 24)) / (60 * 60))
    const remainingMinute = Math.floor((timediff % (60 * 60)) / 60)
    const remainingSecond = Math.floor((timediff % 60))

    setRDays(remainingDays);
    setRHour(remainingHour);
    setRMin(remainingMinute);
    setRSec(remainingSecond);
    return 0


  }


  useEffect(() => {
    if (functStatus === 0) {
      interval.current = setInterval(function () {
        setfunctStatus(getTimeTL());
      }, 1000);
    } else {
      clearInterval(interval.current)
    }
    return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    props.type === 2 ?
      functStatus === 0 ?
        <div >


          {rDays === 9999 ? <Icon loading name='clock outline' /> : ("0" + rDays).slice(rDays > 100 ? -3 : -2)}:
          {rHour === 9999 ? <Icon loading name='clock outline' /> : ("0" + rHour).slice(-2)}:
          {rMin === 9999 ? <Icon loading name='clock outline' /> : ("0" + rMin).slice(-2)}:
          {rSec === 9999 ? <Icon loading name='clock outline' /> : ("0" + rSec).slice(-2)}
        </div>
        : props.beautifulText === "1" || props.beautifulText === "2" ? <div style={{ marginTop: 5 }}>{props.textValue}<br /> </div> : props.textValueFinish
      :
      <div className={`${props.sizeMini || width < 700 ? styles.countdownsizeMini : styles.countdown}`}>

        {props.type === 1 ?
          functStatus === 0 ?
            <div className={`${styles.cdt1}`}>
              {props.textValue}
              <ul >
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rDays === 9999 ? <Icon loading name='clock outline' /> : ("0" + rDays).slice(rDays > 100 ? -3 : -2)}</span>{props.sizeMini || width < 700 ? t("timer.dni") : t("timer.dni")}</li>
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rHour === 9999 ? <Icon loading name='clock outline' /> : ("0" + rHour).slice(-2)}</span>{props.sizeMini || width < 700 ? "HH" : t("timer.godzin")}</li>
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rMin === 9999 ? <Icon loading name='clock outline' /> : ("0" + rMin).slice(-2)}</span>{props.sizeMini || width < 700 ? "MM" : t("timer.minut")}</li>
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rSec === 9999 ? <Icon loading name='clock outline' /> : ("0" + rSec).slice(-2)}</span>{props.sizeMini || width < 700 ? "SS" : t("timer.sekund")}</li>
              </ul>
            </div>
            : props.beautifulText === "1" || props.beautifulText === "2" ? <div style={{ marginTop: 5 }}>{props.textValue}<br /> <Message compact
              success={props.beautifulText === "1" ? true : false}
              info={props.beautifulText === "2" ? true : false}
              header={props.textValueFinish}

            /></div> : props.textValueFinish
          :

          functStatus === 0 ?
            <div className={`${styles.cdt}`}>
              {props.textValue}
              <ul >
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rDays === 9999 ? <Icon loading name='clock outline' /> : ("0" + rDays).slice(rDays > 100 ? -3 : -2)}</span>{props.sizeMini || width < 700 ? t("timer.dni") : t("timer.dni")}</li>
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rHour === 9999 ? <Icon loading name='clock outline' /> : ("0" + rHour).slice(-2)}</span>{props.sizeMini || width < 700 ? "HH" : t("timer.godzin")}</li>
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rMin === 9999 ? <Icon loading name='clock outline' /> : ("0" + rMin).slice(-2)}</span>{props.sizeMini || width < 700 ? "MM" : t("timer.minut")}</li>
                <li style={{ backgroundColor: props.cdtType === "red" ? "#ff7878" : "" }}><span>{rSec === 9999 ? <Icon loading name='clock outline' /> : ("0" + rSec).slice(-2)}</span>{props.sizeMini || width < 700 ? "SS" : t("timer.sekund")}</li>
              </ul>
            </div>
            : props.beautifulText === "1" || props.beautifulText === "2" ? <div style={{ marginTop: 5 }}>{props.textValue}<br /> <Message compact
              success={props.beautifulText === "1" ? true : false}
              info={props.beautifulText === "2" ? true : false}
              header={props.textValueFinish}

            /></div> : props.textValueFinish
        }

      </div>





  );
}

export default CountDownTimer;

