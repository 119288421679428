import React, { useEffect, } from 'react';
import { Button,  Icon, Label, Message, Segment, Table } from 'semantic-ui-react'
import { getFreePlace, getInfroMatchPlace2 } from '../../../myfunctions';



function CurrentDuelsPlayingSwiss(propsRoot) {


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps

    
        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.tournamentGroups])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>

            {propsRoot.tournamentGroups && propsRoot.tournamentGroups.length > 0 ?
                <>
                    <Segment className='hiddenPrint' style={{ marginBottom: 10, marginTop: 10, backgroundColor: "#eeeeee" }}>
                        <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                            <Icon name="winner" /> Tabela pomocnicza
                        </Label>
                       

                        <div style={{ overflowX: "auto", width: "100%" }} >
                        {propsRoot.tournamentJsonRoot && propsRoot.tournamentJsonRoot.match_places_amount  && propsRoot.tournamentJsonRoot.match_places_amount > 0 && propsRoot.tournamentJsonRoot.match_places_amount!==null ?
                                <>
                                    <Message size='mini'
                                        header='Aktualne pojedynki'
                                    />

                                    <Table singleLine size='small' celled striped compact unstackable >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={1} textAlign='center'>RUNDA</Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center' style={{ lineHeight: "12px" }}>Miejsce<br /><span style={{ fontSize: 10 }}>pojedynku</span></Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Pojedynek</Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {propsRoot.tournamentGroups.map((row, i) =>
                                                row.matches.length > 0 ? row.matches.map((line4, i) =>
                                                    line4.match_place !== null && line4.member1_score === null && line4.member2_score === null ?
                                                        <Table.Row>
                                                            <Table.Cell width={1} textAlign='center'>
                                                                {row.round_nr}
                                                            </Table.Cell>
                                                            <Table.Cell width={1} textAlign='center' style={{ position: "relative" }}>
                                                                {getInfroMatchPlace2(line4.match_place)}

                                                            </Table.Cell>
                                                            <Table.Cell textAlign='center' style={{ position: "relative" }}>

                                                                <b>{line4.member1_name}</b> vs <b>{line4.member2_name}</b>
                                                            </Table.Cell>
                                                            <Table.Cell width={1} textAlign='center'>
                                                                <Button size='mini' color='orange' fluid onClick={() => propsRoot.openModal(line4)}>Wprowadź wynik</Button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        : ""
                                                ) : ""
                                            )}
                                        </Table.Body>
                                    </Table>
                                    <Message size='mini'>
                                    Wolne miejsca do gry - nr: {getFreePlace(propsRoot.tournamentJsonRoot.match_places_amount,propsRoot.tournamentJsonRoot.playing_match_places)}
                                    </Message>

                                </>
                                :<Message size='mini'> Proszę zdefiniować ilość miejsc do rozgrywania pojedynków</Message> 
                            }

                        </div>
                    </Segment>
                </>
                :
                ""
            }
        </div>

    )
}

export default CurrentDuelsPlayingSwiss;